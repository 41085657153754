.Score__landscape{
    display: none;
}

@media (max-height: 505px) and (orientation: landscape) {

    .camera {
        display: none;
    }
    .guide{
        display: none;
    }
    .Score__landscape {
        overflow: hidden;
        display: flex;
        width: 100%;
        height: 90vh;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        flex-direction: column;
        color: gray;
        background-color: white;
        position: absolute;
        z-index: 5;

      
    }
    .Score__landscape img{
            width: 5rem;
    }
}