
@font-face {
    font-family: 'Righteous';
    src: url('../fonts/Righteous-Regular.ttf');
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap');

.aurasocials h1 {
    font-size: 55px;
    font-family: 'Montserrat';
    font-weight: 700;
    padding: 1rem 0;
}

.aurosocials h2 {
    font-size: 40px;
    font-family: 'Montserrat';
    font-weight: 700;
}
.aurosocials p{
    font-family: 'Montserrat';
    font-weight: 300;
    margin-bottom: 1rem;
}
.aurosocials h5{
    font-family: 'Montserrat';
    font-weight: 300;

}

.aurasocials {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

}

/* header */
.headermypyme {
    z-index: 5;
    width: 100%;
    display: flex;
    height: auto;
    background-color: transparent;
    padding:0.2rem 0;
}

.headermypyme__logo {
    width: 90%;
    max-width: 1490px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.headermypyme__logo a img {
    width: 100%;
    height: 100%;
}

.headermypyme__logo a {
    padding: 0.2rem;
    width: 9rem;
}
.headermypyme--active{
    z-index: 7;
    position: absolute;
    top: 0;
}
/* landing */
.landing {
    height: auto;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 6;

}
.landing__one--container{
    background-image: url('../images/hands__mockup_mobile.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 62% ;
    background-position-x: 120%;

}
.landing__sections{
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 5rem 0;
    padding-bottom: 0rem;

}
.landing__sections--title{
    text-align: center;
    margin-bottom: 6%;
    margin-top: 3%;
}
/* FirstSection */
.landing__one--section {
    width: 99%;
    height: 95vh;
    display: flex;
    max-width: 1600px;
    margin: 0 auto;
    justify-content: center;
}

.landing__one--text {
    width: 65%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 1rem;
    margin-right: 2rem;
    max-width: 840px;
}

.landing__one--image {
    width: 35%;
    display: flex;
    align-items: center;
}
.landing__one--image img{
    display: none;
}
.landing__one p {
    font-size: 1.2rem;
    line-height: 1.2;
}

.landing__one h2 {
    font-size: 1.5rem;
    margin-top: 0.2rem;
    font-weight: 700;
    margin-bottom: 1rem;

}

.landing__two--text {
    width: 100%;
    padding: 1rem;
    border-radius: 1rem;
}

.landing__two--container {
    display: flex;
    width: 100%;
}

.landing__two--title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: 8% auto;
    margin-top: 0;
    padding-top: 4rem;
}

.landing__two--title p {
    font-weight: 300;
    margin-bottom: 0;
}


.landing__one--button {
    display: flex;
    align-items: center;
}

.landing__one--button span {
    margin-left: 0.5rem;
}

#button--intro {
    width: 37%;
    min-width: 12rem;
    margin-right: 0.1rem;
}

.landing__one--flag {
    display: flex;
    align-items: center;
    align-content: center;
}

.landing__one--flag h5 {
    margin-bottom: 0;
    margin-right: 0.4rem;
    font-size: 1rem;
    font-weight: 400;
}

.landing__one--flag img {
    width: 2rem;
}

.landing__circle {
    width: 37%;
            display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.landing__circle  img{
    height: auto;
    width: 30rem;
    
}

.landing__opurtunites {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
}
.landing__opurtunites h2{
    font-size: 35px;
    margin-bottom: 1rem;
}

/* Onesection */
.landing__01 {
    width: 100%;
    height: 100%;
}

.landing__01--h4{
    width: 0;
    height: 0;
    font-size: 5rem;
    position: relative;
    z-index: 1;
    color: rgba(141, 141, 141, 0.601);
    bottom: 3rem;
    left: -5%;
    font-family: 'Righteous';
}

.landing__01--container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 358px;
    padding: 1rem;
    background-color: #5532c810;
    position: relative;
    z-index: 3;
    border-radius: 2.8rem;

 
}

.landing__circle--numbers {
    width: 40%;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
}


.landing__circle--numbers img {
    width: 80%;
    height: auto;
}


/* SecondSection */
.landing__02 {
    width: 80%;
    height: auto;
    border-radius: 2.8rem;
    max-width: 991px;
    margin: 9rem auto;


}

.landing__02 h4 {
    width: 0;
    height: 0;
    position: relative;
    right: -94%;
    bottom: 3rem;
    font-size: 5rem;
    color: rgba(141, 141, 141, 0.601);
    z-index: 1;
    font-family: 'Righteous';
}

.landing--button {
    width: 24rem;
    border: none;
    color: white;
    border-radius: 0.3rem;
    cursor: pointer;
    background-color: #5532c8;
    width: 40%;
    min-width: 20rem;
    max-width: 10rem;
    height: 3rem;
}

.landing--button:hover {
    opacity: 0.5;
}

.section--fourth--button {
    width: 24rem;
    border: none;
    color: white;
    border-radius: 0.3rem;
    cursor: pointer;
    background-color: #5532c8;
    width: 100%;
    min-width: 13rem;
    max-width: 20rem;
    height: 3rem;
    margin-top: 1rem;
}

.section--fourth--button:hover {
    opacity: 0.5;
}

.landing__02--container {
    width: 100%;
    display: flex;
    height: 358px;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
    z-index:4;
    padding: 1rem;
    background-color: #5532c810;
    border-radius: 2rem;
}
.landing__02--container h2{
    font-size: 35px;
    margin-bottom: 1rem;
}

.landing__02--text {
    width: 60%;
    display: flex;
    padding-right: 2rem;
    justify-content: center;
    flex-direction: column;
    padding-left: 2.5rem;
}

.landing__circle--image {
    width: 10rem;
    height: 10rem;
}

.circle--2 {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
}

.circle--2 h4 {
    position: relative;
    left: -3rem;
    width: 0;
    font-size: 5rem;
}

.circle--2 img {
    width: 80%;
    height: auto;
}
.section--two--container{
    background-color: #f5f5f7;
}
.section--two {
    display: flex;
    width: 80%;
    max-width: 991px;
    margin: 0 auto;
    padding-bottom: 5.5rem;
}

.landing__number--title {
    font-size: 1.5rem;
    font-weight: 700;
}

.section--third {
    display: flex;
    width: 80%;
    max-width: 991px;
    margin: 10% auto;
    flex-wrap: wrap;
    margin-top: 0;

}

/* section-fourth */

.section--fourth {
    width: 100%;
    background-color: #b797cf;
    background-image: url('../images/banner_bottom.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.section--fourth p {
    color: black;
    margin-bottom: 1rem;
    width: 60%;
}

.section--fourth h2 {
    margin-bottom: 1rem;
}

.section--fourth h5 {
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
}

.landing__fourth {
    height: 30rem;
    max-width: 991px;
    margin: 0 auto;
    width: 100%;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-content: center;
    text-align: center;

}

.left {
    margin: 0 !important;
}
/* footer */
.footerlanding {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 5rem;
    padding-left: 5%;
}

.footerlanding__logoaura {
    max-width: 6rem;
    width: 5%;
    margin-right: 10%;
}

.footerlanding__logoaura img {
    width: 70%;
}


.foooterlanding__privacy a:hover{
    color: #5532c8;

}
.footer__text{
    width: 60%;
    display: flex;
    justify-content: space-evenly;
}


@media (max-width:992px) {
    .aurasocials h1{
        font-size: 40px;
    }
    .aurasocials h2{
        font-size: 27px;
        margin-bottom: 0.5rem;
    }
    .landing__one--container{
        background: none;
    }
    .landing__02--text{
        padding: 0 1rem;
    }
    .landing__one--image img {
    display: block;
    width: 100%;
    }
    .aurosocials h1 {
        font-size: 45px;
    }

    .aurosocials h2 {
        font-size: 34px;
    }

    .aurosocials p {
        font-size: 18px;
    }
    .landing__one--section{
        width: 97%;
    }
    /*header */

    .section--fourth h2 {
        font-size: 34px;
    }

    /* landign one*/
    .landing__opurtunites{
        padding-right: 1rem;
    } 
    .landing__one {
        width: 100%;
        margin-bottom: 1rem;

    }
    .landing__sections--title{
        margin-bottom: 12%;
    }
    .landing__01 h4{
        right: 2rem;
    }
    .landing__two {
        width: 100%;
        margin-bottom: 0;
    }
    .landing__circle{
        padding-right: 0;
        width: 53%;
    }
    .landing__circle img{
        width: 100%;
    }
    .landing__one--flag {
        justify-content: left;
    }

    .landing__one h2 {
        text-align: center;
    }

    .landing__one p {
        text-align: center;
    }

    .circle--2 h4 {
        left: -4rem;
    }

    .landing__02 h4{
        right: -91%;
    }

    .landing--button {
        min-width: 18rem;
    }

    .section--third {
        margin: 10% auto;
    }

    .landing__02 {
        margin: 10% auto;
    }
    .footer__text{
        width: 75%;
    }
}

@media (max-width:736px) {
    .aurasocials h1 {
        font-size: 31px;
    }

    .aurosocials h2 {
        font-size: 34px;
        text-align: center;
    }
    .aurosocials h4{
        font-size: 3rem;
    }
    .aurosocials p {
        font-size: 18px;
        text-align: center;
    }   
    .aurosocials h5{
        font-size: 1rem;
    }

    /* header */
    .headermypyme__logo {
        width: 100%;
        height: 50px;
        justify-content: center;
    }
    .headermypyme__logo a{
        margin-right: 0;
    }
    .background-header{
        height: 50px !important;
    }
    .headermypyme{
        height: 50px;
    }
    .landing {
        margin-top: 100px;
        padding-top: 1rem;
    }

    /* landing one */
    .landing__one--section {
        margin-bottom: 2rem;
        flex-direction: column-reverse;
        height: auto;
    }

    .landing__one--text {
        width: 100%;
        text-align: center;
        padding: 2rem;
    }

    .landing__one--image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .landing__one--image img {
        width: 50%;
    }

    .landing__02--container {
        width: 90%;
        margin: 0 auto;
    }

    .landing__02--text {
        width: 100%;
        margin-top: 4rem;
        padding-right: 0;
        margin-bottom: 2rem;
        padding-left:0 ;
    }

    .landing--button {
        width: 100%;
        border: none;
        color: white;
        border-radius: 0.3rem;
        cursor: pointer;
        background-color: #5532c8;
        min-width: auto;
        max-width: none;
        height: 3rem;
        margin: 0 auto;
    }

    .section--fourth--button {
        max-width: 18rem;
    }

    .landing__section {
        padding: 0 1rem;
    }

    .landing__one {
        padding-right: 1rem;
    }

    .landing__one--button {
        flex-direction: column;
    }

    .landing__one--button span {
        margin-left: 0;
        margin-top: 0.2rem;
    }

    .landing__one--flag {
        justify-content: center;
    }

    .landing__one h2 {
        text-align: center;
    }

    .circle--2 h4 {
        position: static;
        color: #5532c8;
    }

    .landing__circle--numbers {
        color: #5532c8;
        position: static;
        width: 100%;
        justify-content: center;
        padding: 0 1rem;
    }

    .landing__01 {
        height: auto;
    }

    .landing__02 {
        padding: 1rem 0;
        height: auto;
    }
    .section--two{
        padding-bottom: 2rem;
        width: 85%;
    }
    .landing__sections--title{
        margin-bottom: 18%;
    }
    .landing__02 h4{
            right: -78%;
        bottom: 2.5rem;
        font-size: 4rem;
    }
    .landing__01--h4{
        right: 0;
        bottom: 2.25rem;
        font-size: 4rem;
    }
    .landing__03 {
        padding: 0;
    }

    .landing__04 {
        padding: 1rem 0;
    }

    .landing__circle--numbers h4 {
        position: static;
        width: auto;

    }

    .landing__01--container {
        padding: 2rem 0;
        flex-direction: column-reverse;
        height: auto;
    }

    .landing__02--container {
        padding: 0;
        width: 100%;
        padding: 0 1rem;
        flex-direction: column-reverse;
        padding-bottom: 1rem;
        height: auto;
    }

    .landing__01--container h4 {
        position: relative;
        width: 0;
    }

    .circle--2 {
        width: 100%;
        justify-content: center;
    }

    .circle--2 img {
        max-width: 20rem;
    }

    .landing__circle--numbers img {
        max-width: 17rem;

    }

    .circle--2 h4 {
        position: relative;
        width: 0;
    }

    .landing__opurtunites {
        width: 100%;
        padding: 0 1rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .landing__fourth {
        height: 40rem;
    }

    .section--fourth h2 {
        font-size: 2rem;
    }

    .section--fourth p {
        width: 80%;
    }
    /* footer */ 
    .footerlanding {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        height: auto;
        flex-direction: row;
        padding: 1rem;
    }

    .footerlanding__logoaura {
        width: 18%;
        margin-bottom: 1rem;
        margin: 0;
    }

    .footerlanding__logoaura img {
        width: 100%;
    }

    .footerlanding__main {
        width: 100%;
        display: flex;
        font-size: 1rem;

        justify-content: flex-end;
    }
    .footer__text{
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .footer__text div{
        width: 100%;
    }
    .foooterlanding__privacy{
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding-left: 7rem;
    }
    /*section two */
    .landing__two--container {
        flex-direction: column;
        text-align: center;
    }

    .landing__circle {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0;
        align-items: center;
    }

    .landing__circle img {
        display: flex;
        width: 89%;
    }
    .landing__two--text{
        padding: 0;
    }

}