.emailverification {
    width: 100%;
    height: 100vh;
    background-color: #f5f5f7;

}

.emailverification__container {
    width: 90%;
    max-width: 1200px;
    height: 100%;
    display: flex;
    margin: 0 auto;
}

.emailverification__container--texto {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding-top: 3rem;
    padding-right: 3rem;

}

.emailverification__container--texto h2 {
    font-size: calc(1.375rem + 1.5vw);
}

.emailverification__container--img , .emailverification__container--404 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-left: 3rem;
    width: 100%;
}
.emailverification__container--img ,.emailverification__container--404  img {
    width: 100%;
}
a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  margin-right: 1rem;
}
.email__container--button {
    border: none;
    height: 3rem;
    width: 40%;
    color: white;
    max-width: 30rem;
    background-color: #5532c8;

    border-radius: 0.5rem;
}

.email__container--button:hover {
    opacity: 0.5;

}

.email__socials {
    width: 100%;
    height: 3rem;
    display: block;

    border-radius: 1rem;
    justify-content: flex-start;
    margin-top: 2rem;

}

.email__socials--imgs {
    width: 100%;
    height: 3rem;
    display: flex;
    border-radius: 1rem;
    justify-content: flex-start;

    margin-top: 1rem;
}

.email__socials--imgs img {
    width: 5rem;
    margin-right: 1rem;
    padding: 0.5rem;
    background-color: #5532c8;
    cursor: pointer;
    border-radius: 1rem;
    height: 100%;
}

.email__socials--imgs img:hover {
    opacity: 0.5;
}
.emailverification--p{
    white-space: pre-line;
    width: 100%;
}
@media (max-width: 992px) {
    .emailverification__container--img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        padding-left: 3rem;
        width: 100%;
    }
    .email__container--button {
        width: 70%;
    }   
    .emailverification__container--img , .emailverification__container--404 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
        padding-left: 0;
    }
    .emailverification__container--404 img{
        width: 100%;

    }
}

@media (max-width: 767px) {
    .email__socials--follow{
        text-align: center;
    }
    .emailverification__container {
        flex-direction: column-reverse;
        justify-content: center;
    }

    .email__container--button {
        width: 100%;
    }

    .emailverification__container--img , .emailverification__container--404 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
        padding-left: 0;
    }
    .emailverification__container--404 img{
        width: 100%;

    }
    .emailverification__container--img img{
        width: 80%;
        display: none;
        
    }

    .emailverification__container--texto {
        padding: 0;
    }
    .email__socials--imgs{
        justify-content: space-evenly;
    }
   
}