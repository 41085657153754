.intereses {
    width: 100%;
    margin: 0 auto;
    height: auto;
    background-color: white;
    padding: 3rem 0;
  }
  
  .offset-lg-2-3 {
    margin: 0;
  
  }
  .intereses p {
    font-weight: 500;
    color: #6e6b78;
    font-size: 18px;
  }
  
.intereses__cards {
    width: 90%;
    max-width: 1200px;
    height: 30rem;
    margin: 0 auto;
    border-radius: 2rem;
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .intereses__cards--box {
    background-color: #f5f5f7;
    border-radius: 2rem;
    height: 100%;
    max-height: 100%;
    width: 33%;
    padding: 2rem;
    margin-right: 1rem;
  }
  .intereses__cards--box:hover {
    box-shadow: 0px 0px 9px 0px rgb(241, 164, 255);
  }
  .intereses__cards--box h3 {
    font-size: 28px;
    height: 25%;
    margin-bottom: 2rem;
  }
  
  .intereses__cards--images {
    width: 23%;
    margin-bottom: 5rem;
  }
  
  .intereses--titulos {
    width: 90%;
    text-align: left;
    max-width: 1150px;
    margin: 0 auto;
    margin-bottom: 2rem;
  }

@media (max-width: 992px) {
  .intereses__cards {
    flex-direction: row;
    justify-content: unset;
    height: auto;
  }
  .intereses__cards--box {
    width: 33%;
    height: 22rem;
    padding: 0.8rem;
    margin-bottom: 1rem;
  }
    .intereses__cards--box h3 {
      width: 100%;
      font-size: 26px;
      height: 20%;
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3rem 0;
    }
  
    .intereses__cards--box p {
      width: 100%;
      font-size: 18px;
      margin-bottom: 5rem;
    }
    
  .intereses__cards--images{
    margin-bottom: 1rem;
  }
  
}

@media (max-width: 767px) {

  .intereses__cards{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: none;
    margin: auto;
    margin-bottom: 0;
    height: auto;

  }
  .intereses__cards--box {
    margin: 1rem 0;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    height:auto;
    width: 100%;
  }
  .intereses__cards--box p{
    margin-bottom: 1rem;
  }
  .intereses__cards--box h3 {
    width: 100%;
    font-size: 25px;
    text-align: center;
    height: auto;
    padding: 1rem 0;
    margin-bottom: 1rem;
  }

}