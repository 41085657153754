.AhorrroInversion{
    margin-top: 130px;
    height: 43rem;
    padding-left: 0;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
  }
  .AhorrroInversion__texto {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: center;
    z-index: 5;
    position: relative;
    z-index: 1;
    padding-left: 3rem;
  
  }
  .AhorrroInversion__texto h2 {
    font-size: 50px;
  }
  .AhorrroInversion__phone {
    width: auto;
    bottom: 50rem;
    margin-left: 20%;
    display: flex;
    align-items: center;
  }

  .AhorrroInversion__phone img {
    width: 285px;
    height: auto;
    max-width: 320px;
  }

  @media (max-width: 992px) {
    .AhorrroInversion__texto {
        padding-left: 3%;
        padding-right: 0;
        width: 59%;

      }
    
      .AhorrroInversion__texto p {
        color: #6e6b78;
        text-align: left;

      }
      .AhorrroInversion__texto h2{
        text-align: left;
        font-size: 38px;
      }
      .AhorroInversion__subline {
        font-size: 16px;
      }
    
      .AhorrroInversion__phone {
        bottom: 50rem;
        margin-left: 2%;
        width: 50%;
      }
    .AhorroInversion__button{
      width: 60%;
    }
    
  }
  
  @media (max-width: 767px) {
    .AhorroInversion__button{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .AhorrroInversion__texto h2{
        font-size: calc(1.325rem + .9vw);
        
      }
      .AhorrroInversion{
        padding-left: 0%;
        height: auto;
        padding: 0;
        display: flex;
        flex-direction: column-reverse;
        margin:3rem auto;
      }
      .AhorrroInversion__texto {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: 1rem;
        padding: 0 1.2rem;
      }
    
      .AhorrroInversion__phone img {
        width: 175px;
        height: auto;
        max-width: 780px;
      }
    
      .AhorrroInversion__phone {
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 10;
        margin: 0 auto;
        height: auto;
        margin-bottom: 1rem;
    
      }
  }