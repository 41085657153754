.modalcoming {
    width: 100%;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
  }
  .closemodal {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
    background-color: rgba(234, 0, 255, 0.178);
  }
  .modalcoming__main {
    background-color: white;
    width: 40%;
    
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 1rem;
    position: fixed;
    z-index: 111;
  }
  .modalcoming__main p {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 2rem;
    text-align: center;
  }
  .modalcoming__main button {
    border: none;
    color: white;
    border-radius: 0.3rem;
    cursor: pointer;
    background-color: #5532c8;
    width: 40%;
    height: 3rem;
    min-width: 14rem;
    margin: 0 auto;
  }
  .modalcoming__main button:hover {
    opacity: 0.5;
  }
  @media (max-width: 991px) {
    .modalcoming__main {
      width: 90%;
      height: 50%;
      max-height: 300px;
      max-width: 500px;
    }
    .modalcoming__main p {
      width: 75%;
    }
  }
  