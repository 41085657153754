
/* 
---------------------------------------------
Services Style
--------------------------------------------- 
*/

.services {
    padding: 4rem 0;
    background-color: #f5f5f7;
    position: relative;
}

.services .section-heading p {
    margin-top: 10px;
    margin-left: 10%;
    letter-spacing: 0px;
    margin-right: 10%;

}

.services .container-fluid {
    padding-left: 65px;
    padding-right: 65px;
}


.services .section-heading {
    text-align: center;
    margin-bottom: 2rem;
}

.services .section-heading .line-dec {
    margin: 0 auto;
}

.cards {}

.col-lg-3 {
    width: 24%;
}

.service-item {
    position: relative;
    z-index: 1;
    padding: 30px;
    width: 100%;
    height: 22rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 10px;
    border-top-right-radius: 50px;
    transition: all .3s;
    margin-right: 0.3rem;
    height: 100%;

}

.service-item .icon {
    margin-left: 0px;
    margin-bottom: 30px;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    transition: all .3s;

}

.service-item h4 {
    transition: all .3s;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
}

.service-item p {
    transition: all .3s;
    font-size: 15px;
    margin-bottom: 20px;
}

.service-item:hover h4,
.service-item:hover p,
.service-item:hover .text-button a {
    color: white;
}

.first-service .icon {
    background-image: url(../../images/lupa.svg);
    background-size: contain;
}

.first-service:hover .icon {}

.second-service .icon {
    background-image: url(../../images/score.svg);
    background-size: contain;

}


.third-service .icon {
    background-image: url(../../images/suma.svg);
    background-size: contain;

}



.fourth-service .icon {
    background-image: url(../../images/lista.svg);
    background-size: contain;

}



.service-item:hover {
    background-image: url(../../images/Group\ 15.jpg);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 992px) {
    .services .naccs .menu div {
        font-size: 15px;
        font-weight: 500;
    }

    .service-item {
        text-align: center;
        margin-bottom: 30px;
        height: 18rem;

    }

    .service-item .icon {
        margin: 0 auto 30px auto;
    }
    .cards {
        display: flex;
        flex-direction: column;
    }

.col-lg-3 {
    width: 100%;
}

}

@media (max-width: 767px) {
    .services .section-heading p {
        margin: 0;
    }

    .services {
        padding: 2rem 0;
        margin-bottom: 0rem;
    }

    .service-item .icon {
        float: none;
        margin-right: 0px;
        margin-bottom: 15px;
    }

    .service-item {
        text-align: center;
        padding: 25px;
        width: 100%;
        height: 18rem;
    }
    .col-lg-3 {
        width: 90%;
        margin: 0 auto;
    }

    .service-item .right-content {
        display: inline-block;
    }

    .services .naccs .menu div .thumb {
        padding: 5px;
    }

    .services .icon img {
        margin: 0px;
    }

    .service-item .right-content {
        display: inline-block;
    }

    .services .naccs .menu div .thumb {
        padding: 5px;
    }

    .services .icon img {
        margin: 0px;
    }


    .services ul.nacc li.active {
        padding: 45px;
    }

    .services .naccs .menu div {
        font-size: 0px;
    }

    .services ul.nacc li .right-image img {
        float: none;
    }

}