.servicios{
    padding: 4rem 1rem;
    padding-bottom: 3.6rem;
    text-align: center;
    background-color: #f5f5f7;
  }
  .servicios__container {
    height: 100%;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 1rem;
    text-align: left;
    display: flex;
  }
  
  .servicios__list {
    width: 45%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0.5rem;
    align-content: center;
  }
  
  .servicios__image {
    width: 55%;
  }
  
  .servicios__image img {
    border-radius: 2rem;
  }
  
  .servicios__list--item {
    display: flex;
    margin: 1rem 0;
    width: 50%;
    height: 4rem;
  }
  
  .servicios__list--item img {
    width: 3.5rem;
    min-width: 3.5rem;
    max-width: 3.5rem;
  }
  
  .servicios__list--item p {
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: 0.1rem;
    font-weight: 300;
    text-align: left;
    margin-top: 0.1rem;
    margin-bottom: 0;
  }
  .bandera__container{
    display: flex;
    align-content: center;
  }
  .title--strategy{
    font-size: 40px;
  }
  .title__button--si{
    width: 30%;
    margin-left: 40%;
  }
@media (max-width: 992px) {
    .servicios__container {
        height: 100%;
        margin: 1rem auto;
        flex-direction: column;
      }
    
      .servicios__list {
        width: 100%;
      }
      .servicios__list--item p{
        font-size: 16px;
        font-weight: 300;
      }
      .servicios__image {
        width: 100%;
      }
      .title__button--si{
        width: 40%;
      }
}
@media (max-width: 767px) {

    .servicios{
        padding: 3rem 0.2rem;
        padding-bottom: 7rem;
      }
      .servicios__container{
        width: 100%;
        margin: 2rem auto;
      }
      .servicios__list{
        padding: 0;
        height: 11rem;
        margin-top: 4rem;
      }

}