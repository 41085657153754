.personalidades__container {
    padding: 3.2rem;
}

.personalidades__container p {
    color: #0d081e;
    word-wrap: break-word;
    padding: 1%;
    text-align: center;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

#button--intro  {
    width: 100%;
    margin: 0 auto !important;
    }

.container__button--personalidades {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.personalidades__title {
    text-align: center;
    height: auto;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.personalidades__title h2 {
    font-size: 40px;
}



.personalidades__title em {
    font-style: normal;
    color: #5532c8;
    padding: 0.2rem;
}

.container {
    margin-bottom: 0;
}

em {
    font-style: normal;
    color: #5532c8;
    padding: 0.2rem;
}

.personalidades {
    width: 100%;
    background-color: #f8ecd9;
}

.personalidades .section-heading {
    margin-bottom: 45px;
}

.personalidades .section-heading,
.personalidades .box-item,
.personalidades .box-item .gradient-button,
.personalidades .box-item span {
    position: relative;
    z-index: 1;
}

.personalidades .box-item {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
    padding: 10px 30px;
    background-color: #fff;
    border-radius: 40px;
    margin-bottom: 30px;
}

.personalidades .box-item h4 a {
    font-size: 20px;
    font-weight: 700;
    margin-top: 8px;
    color: #2a2a2a;
    transition: all .3s;
}

.personalidades .box-item p {
    margin-bottom: 0px;
}

.personalidades .box-item:hover h4 a {
    color: #5532c8;
}

.personalidades .gradient-button {
    margin-top: 30px;
    margin-bottom: 10px;
}

.personalidades span {
    font-size: 14px;
    color: #7a7a7a;
}

.personalidades .right-image {
    position: relative;
    z-index: 1;
}

@media (max-width: 767px) {
    .personalidades__title h2{
        padding:  0 0.5rem;
        font-size: calc(1.325rem + .9vw);
      }
    
      
  .about-us .about-item {
    margin-top: 15px;
  }
  .personalidades__container{
      padding: 2.9rem 0;
      width: 100%;
  }

}