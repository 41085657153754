.main-banner {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 270px 5%;
    position: relative;
    overflow: hidden;
    height: 100vh;
    display: flex;
    align-items: center;

}

.col-lg-12 {
    width: 100%;
}

.main-banner:after {
    content: '';
    background-image: url(../../images/Group\ 17.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.main-banner .left-content {
    margin-right: 15px;
    max-width: 520px;

}

.main-banner .left-content h2 {
    z-index: 2;
    position: relative;
    font-weight: 700;
    line-height: 70px;
    font-size: 50px;
    margin-bottom: 20px;
}

.main-banner .left-content p {
    color: white;
    font-weight: 400;
    margin-bottom: 45px;
    width: 100%;
    max-width: 476px;
}

#container__inicio--country {
    margin-bottom: 0;

}

.main-banner .left-content .first-button {
    margin-right: 15px;
}

.main-banner .left-content .white-button {
    display: inline-block;
}

.main-banner .right-image {
    text-align: center;
    position: relative;
    z-index: 20;
}

.main-banner .right-image img {
    max-width: 710px;
}

.main-banner .left-content .white-button a {
    background-color: #5532c8;
    color: #fff !important;
}

.banner__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
}

.banner__button--free {
    width: 100%;
    color: white;
    margin-bottom: 0;
    font-size: 1.3rem;
    margin-right: 0.4rem;
}
.banner__button--free::after {
    content: ' -';
}

@media (max-width: 1200px) {
    .main-banner .left-content h2 {
        z-index: 2;
        position: relative;
        font-weight: 700;
        line-height: 70px;
        font-size: 50px;
        margin-bottom: 20px;
    }
}

@media (max-width: 992px) {
    .banner__button--free {
        width: 100%;
        color: #5532c8;
        margin-bottom: 0;
        text-align: center;
        font-size: 1.3rem;
        margin-right: 0.4rem;
        padding-top: 0.2rem;
    }
    .banner__button--free::after {
        content: '';
    }
    .banner__button--free::before {
        content: '';
    }
    .main-banner:after {
        display: none;
    }

    .banner__button {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        align-content: center;
    }
    
    .main-banner .left-content p {
        width: 100%;
        color: #6e6b78;
        margin: 1rem 0;
    }

    #container__inicio--country {
        width: 100%;
    }

    .main-banner .left-content {
        margin: 0 auto;
    }

    .future__finance {
        width: 100%;
        padding-bottom: 0;

    }

    .main-banner .left-content {
        width: 100vw;
    }

    .main-banner {
        text-align: center;
        padding-top: 5rem;
        height: auto;
        text-align: center;
        padding: 126px 0px 30px 0px;
    }

    .main-banner:before {
        display: none;
    }

    .main-banner .right-image {
        margin: 30px auto 0px auto;
        text-align: center;
    }

    .main-banner .right-image img {
        width: 550px;
    }

}

@media (max-width: 767px) {

    .main-banner {
        padding-top: 5rem;
        height: 100%;
        background-color: #fff8e4;
        text-align: center;
    }

    .main-banner .left-content p {
        width: 100%;
        color: #6e6b78;
        margin: 1rem 0;
    }

    #container__inicio--country {
        width: 100%;
    }

    .main-banner .left-content {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .future__finance {
        width: 96%;
        padding-bottom: 0;
        margin: 0;

    }

    .main-banner .right-image img {
        width: 100%;
    }

    .main-banner .info-stat {
        margin-bottom: 15px;
    }
}
/*
    flechas 
    mexico acentos
    x button

*/
