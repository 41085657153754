/*
Table of contents
------------------------------------------------
01. font & reset css
02. reset
03. global styles
04. header
05. banner
06. features
07. testimonials
08. contact
09. footer
10. preloader
11. search
12. portfolio

--------------------------------------------- */
/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/
/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
._dp_container-content{
  margin-top: -2.4rem;
  background: none !important;
  max-height: 100%;
  height: 100%;

}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #5532c8; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
p{margin-bottom: 1rem;}
/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

.grid-sizer,
.grid-item {
  width: 50%;
}

.grid-item {
  float: left;
}

.grid-item img {
  display: block;
  max-width: 100%;
}
.container__pasos{
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

.imagelogo {
  width: 100%;
  height: 100%;
}


* html .clearfix {
  height: 1%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  background-color: #fff;

  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

.app h1 
h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 50px;
}

.app h3 {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 1rem;
}
.app p{
  color: #0d081e;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
}
.app ul {
  margin-bottom: 0px;
}


img {
  width: 100%;
  overflow: hidden;
}

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
html,
body {
  background: #fff;
  font-family: 'Roboto', sans-serif;
}

::selection {
  background: #5532c8;
  color: #fff !important;
}

::-moz-selection {
  background: #5532c8;
  color: #fff !important;
}

@media (max-width: 991px) {



  .clients__button {
    border: none;
    color: white;
    border-radius: 0.3rem;
    cursor: pointer;
    background-color: #5532c8;
    width: 35%;
    margin: 0 auto;
    margin-top: 2rem;
    margin-left: 0.2rem;
    height: 3rem;

  }

 
  html,
  body {
    overflow-x: hidden;
  }

  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .mobile-bottom-fix {
    margin-bottom: 30px;
  }

  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }
}



.section-heading h4 {
  color: #2a2a2a;
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.section-heading h4 em {
  font-style: normal;
  color: #5532c8;
}

.section-heading img {
  width: 45px;
  height: 2px;
}

.section-heading p {
  margin-top: 10px;
  letter-spacing: 3px;
}

.show-up {
  position: relative;
  z-index: 2;
}

.white-button a {
  display: inline-block !important;
  padding: 10px 20px !important;
  color: #5532c8 !important;
  text-transform: capitalize;
  font-size: 15px;
  background-color: #fff;
  border-radius: 23px;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  transition: all .5s;
}

.white-button a i {
  margin-left: 5px;
  font-size: 18px;
}

.white-button a:hover {
  color: #5532c8;
}

.text-button span {
  font-size: 15px;
  color: #5532c8;
  position: relative;
  left: 99%;
  bottom: 13vh;
}

.text-button a i {
  margin-left: 5px;
  font-size: 14px;
  transition: all .3s;
}

.text-button a:hover i {
  margin-left: 8px;
}

.gradient-button a {
  display: inline-block !important;
  padding: 10px 20px !important;
  color: #fff !important;
  text-transform: capitalize;
  font-size: 15px;
  background: rgb(19, 175, 240);
  background: linear-gradient(105deg, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%);
  border-radius: 23px;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  transition: all .5s;
}

.gradient-button a:hover {
  color: #fff !important;
  background: rgb(19, 175, 240);
  background: linear-gradient(105deg, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%);
}

.border-button a {
  display: inline-block !important;
  padding: 10px 20px !important;
  color: #5532c8 !important;
  border: 1px solid #5532c8;
  text-transform: capitalize;
  font-size: 15px;
  display: inline-block;
  background-color: #fff;
  border-radius: 23px;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  transition: all .5s;
}

.border-button a:hover {
  background-color: #5532c8;
  color: #fff !important;
}


/* 
---------------------------------------------
header
--------------------------------------------- 
*/

.background-header {
  background-color: #fff !important;
  height: 80px ;
  border-bottom: none !important;
  position: fixed !important;
  top: 0px;
  left: 0px;
  right: 0px;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.03);
}

.background-header .logo,
.background-header .main-nav .nav li a {
  color: #fff;
}

.background-header .main-nav .nav li:hover a {
  color: #5532c8;
}

.background-header .nav li a.active {
  position: relative;
  color: #fff;
}

.background-header .nav li a.active:after {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #5532c8;
  content: '';
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
}

.background-header .nav li:last-child a.active:after {
  background-color: transparent;
}
.header-nav{
  background-color: transparent;
}
.header-area {
  position: fixed;
  left: 0px;
  right: 0px;
  z-index: 100;
  height: 80px;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
}
.header-area .main-nav {
  min-height: 80px;
  background: transparent;
}

.header-area .main-nav .logo {
  float: left;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.header-area .main-nav .logo {
  line-height: 100px;
  float: left;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 4rem;
}

.background-header .main-nav .logo {
  line-height: 80px;
  width: 4rem;
}

.background-header .main-nav .nav {
  margin-top: 20px !important;
}

.header-area .main-nav .nav {
  float: right;
  margin-top: 30px;
  margin-right: 0px;
  background-color: transparent;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: absolute;
  right: 20%;
  z-index: 999;
}

.header-area .main-nav .nav li {
  padding-left: 20px;
  padding-right: 20px;
}

.header-area .main-nav .nav li:nth-child(6) {
  padding-right: 0px;
  padding-left: 40px;
}

.header-area .main-nav .nav li:last-child a,
.background-header .main-nav .nav li:last-child a,
.header-area .main-nav .nav li:nth-child(6) a,
.background-header .main-nav .nav li:nth-child(6) a {
  font-weight: 400;
}

.header-area .main-nav .nav li:last-child a:hover,
.background-header .main-nav .nav li:last-child a:hover,
.header-area .main-nav .nav li:nth-child(6) a:hover,
.background-header .main-nav .nav li:nth-child(6) a:hover {
  color: #fff !important;
}

.header-area .main-nav .nav li a {
  display: block;
  font-weight: 500;
  font-size: 15px;
  color: #2a2a2a;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  letter-spacing: 1px;
}

.header-area .main-nav .nav li:hover a,
.header-area .main-nav .nav li a.active {
  color: #5532c8 !important;
}

.header-area .main-nav .nav li:last-child a.active {
  color: #fff !important;
}

.background-header .main-nav .nav li:hover a,
.background-header .main-nav .nav li a.active {
  color: #5532c8 !important;
  opacity: 1;
}

.header-area .main-nav .nav li.submenu {
  position: relative;
  padding-right: 30px;
}

.header-area .main-nav .nav li.submenu:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 12px;
  color: #2a2a2a;
  position: absolute;
  right: 18px;
  top: 12px;
}

.background-header .main-nav .nav li.submenu:after {
  color: #2a2a2a;
}

.header-area .main-nav .nav li.submenu ul {
  position: absolute;
  width: 200px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  top: 50px;
  opacity: 0;
  transform: translateY(+2em);
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
}

.header-area .main-nav .nav li.submenu ul li {
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.header-area .main-nav .nav li.submenu ul li a {
  opacity: 1;
  display: block;
  background: #f7f7f7;
  color: #2a2a2a !important;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid #eee;
}

.header-area .main-nav .nav li.submenu ul li a:hover {
  background: #fff;
  color: #5532c8 !important;
  padding-left: 25px;
}

.header-area .main-nav .nav li.submenu ul li a:hover:before {
  width: 3px;
}

.header-area .main-nav .nav li.submenu:hover ul {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 33px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 40px;
  display: none;
}


.background-header .main-nav .menu-trigger {
  top: 23px;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #2a2a2a;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.background-header .main-nav .menu-trigger span,
.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #2a2a2a;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #2a2a2a;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #2a2a2a;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #2a2a2a;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #2a2a2a;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #2a2a2a;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #2a2a2a;
}


.header-area.header-sticky {
  min-height: 80px;
}

.header-area .nav {
  margin-top: 30px;
}

.header-area.header-sticky .nav li a.active {
  color: #5532c8;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    padding-left: 12px;
    padding-right: 12px;
  }

  .header-area .main-nav:before {
    display: none;
  }
 
}

@media (max-width: 992px) {

  .header-area .main-nav .nav li:last-child,
  .background-header .main-nav .nav li:last-child {
    color: #069;
  }

  .header-area .main-nav .nav li:nth-child(6),
  .background-header .main-nav .nav li:nth-child(6) {
    padding-right: 0px;
  }

  .background-header .nav li a.active:after {
    display: none;
  }
}

@media (max-width: 767px) {

  .pre-header ul.info li:last-child,
  .pre-header ul.info li:nth-child(6) {
    display: none !important;
  }

  .background-header .main-nav .nav {
    margin-top: 80px !important;
  }

  .header-area .main-nav .logo {
    color: #1e1e1e;
    display: flex;
    width: 26%;
    padding-top: 0.5rem;
  }
  .header-area.header-sticky .nav li a:hover,
  .header-area.header-sticky .nav li a.active {
    color: #5532c8 !important;
    opacity: 1;
  }

  .header-area.header-sticky .nav li.search-icon a {
    width: 100%;
  }

  .header-area {
    background-color: #fff;
    padding: 0px 15px;
    height: 100px;
    box-shadow: none;
    text-align: center;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.03);
  }

  .header-area .container {
    padding: 0px;
  }


  .header-area .logo {
    margin-left: 30px;
  }

  .header-area .menu-trigger {
    display: block !important;
  }

  .header-area .main-nav {
    overflow: hidden;
  }

  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }

  .background-header .nav {
    margin-top: 80px;
  }

  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }

  .header-area.header-sticky .nav {
    margin-top: 100px;
  }

  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #e7e7e7;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #191a20 !important;
  }

  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
    color: #5532c8 !important;
  }

  .header-area .main-nav .nav li.submenu ul {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
    top: 0px;
    width: 100%;
    box-shadow: none;
    height: 0px;
  }

  .header-area .main-nav .nav li.submenu ul li a {
    font-size: 12px;
    font-weight: 400;
  }

  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 0px;
  }

  .header-area .main-nav .nav li.submenu ul.active {
    height: auto !important;
  }

  .header-area .main-nav .nav li.submenu:after {
    color: #3B566E;
    right: 25px;
    font-size: 14px;
    top: 15px;
  }

  .header-area .main-nav .nav li.submenu:hover ul,
  .header-area .main-nav .nav li.submenu:focus ul {
    height: 0px;
  }
}

@media (min-width: 767px) {
  .header-area .main-nav .nav {
    display: flex !important;
  }
}

/* 
---------------------------------------------
preloader
--------------------------------------------- 
*/

.js-preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  z-index: 5;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.js-preloader.loaded {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

@-webkit-keyframes dot {
  50% {
    -webkit-transform: translateX(96px);
    transform: translateX(96px);
  }
}

@keyframes dot {
  50% {
    -webkit-transform: translateX(96px);
    transform: translateX(96px);
  }
}

@-webkit-keyframes dots {
  50% {
    -webkit-transform: translateX(-31px);
    transform: translateX(-31px);
  }
}

@keyframes dots {
  50% {
    -webkit-transform: translateX(-31px);
    transform: translateX(-31px);
  }
}

.preloader-inner {
  position: relative;
  width: 142px;
  height: 40px;
  background: #fff;
}

.preloader-inner .dot {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  left: 15px;
  background: #5532c8;
  border-radius: 50%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-animation: dot 2.8s infinite;
  animation: dot 2.8s infinite;
}

.preloader-inner .dots {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  -webkit-animation: dots 2.8s infinite;
  animation: dots 2.8s infinite;
}

.preloader-inner .dots span {
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  background: #5532c8;
  border-radius: 50%;
}



/* 
---------------------------------------------
Banner Style
--------------------------------------------- 
*/

.main-banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 270px 5%;
  position: relative;
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: center;

}
.col-lg-12{
  width: 99%;
}

.main-banner:after {
  content: '';
  background-image: url(../images/Group\ 17.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.main-banner .left-content {
  margin-right: 15px;
  max-width: 520px;

}

.main-banner .left-content h2 {
  z-index: 2;
  position: relative;
  font-weight: 700;
  line-height: 70px;
  font-size: 50px;
  margin-bottom: 20px;
}

#container__inicio--country{
  margin-bottom: 0;
  
}


.main-banner .left-content .first-button {
  margin-right: 15px;
}

.main-banner .left-content .white-button {
  display: inline-block;
}

.main-banner .right-image {
  text-align: center;
  position: relative;
  z-index: 20;
}

.main-banner .right-image img {
  max-width: 710px;
}

.main-banner .left-content .white-button a {
  background-color: #5532c8;
  color: #fff !important;
}

.banderas {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  align-content: center;
}

.banderas img {
  width: 2rem;
  max-width: 2rem;
  min-width: 2rem;
  height: 1.3rem;
  min-height: 1.3rem;
  max-height: 1.3rem;
  cursor: pointer;
}
.banderas__container{
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  justify-content: center;
  top: 2rem;
  padding: 0.2rem 1rem;
  z-index: 6;
}
.banderas__container a{
  margin: 0;
}
.downIcon{
  cursor: pointer;
}
.banderas__closeModal{
  position: absolute;
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  top: -70%;
  left: -120%;
  z-index: 5;
}
iframe{ position: absolute; left:0; right:0; bottom:0; top:0; border:0; }
/*
---------------------------------------------
About Us
---------------------------------------------
*/
/* 
.about__container{
  padding: 3.2rem;
}
.about__container p {
  color: #0d081e;
  word-wrap: break-word;
  padding: 1%;
  text-align: center;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.container__button--about {
  width: 100%;
  display: flex;
  justify-content: center;

  margin-top: 1rem;
}

.about__button {
  border: none;
  color: white;
  border-radius: 0.3rem;
  cursor: pointer;
  background-color: #5532c8;
  width: 15%;
  height: 2.3rem;
  min-width: 14rem;
}
.about__button:hover {
  opacity: 0.5;
}

.about__title {
  text-align: center;
  height: auto;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.about__title h2 {
  font-size: 40px;
}



.about__title em {
  font-style: normal;
  color: #5532c8;
  padding: 0.2rem;
}
.container{
  margin-bottom: 0;
}

em {
  font-style: normal;
  color: #5532c8;
  padding: 0.2rem;
}

.about-us {
  width: 100%;
  background-color: #f8ecd9;

}

.about-us .section-heading {
  margin-bottom: 45px;
}

.about-us .section-heading,
.about-us .box-item,
.about-us .box-item .gradient-button,
.about-us .box-item span {
  position: relative;
  z-index: 1;
}

.about-us .box-item {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  padding: 10px 30px;
  background-color: #fff;
  border-radius: 40px;
  margin-bottom: 30px;
}

.about-us .box-item h4 a {
  font-size: 20px;
  font-weight: 700;
  margin-top: 8px;
  color: #2a2a2a;
  transition: all .3s;
}

.about-us .box-item p {
  margin-bottom: 0px;
}

.about-us .box-item:hover h4 a {
  color: #5532c8;
}

.about-us .gradient-button {
  margin-top: 30px;
  margin-bottom: 10px;
}

.about-us span {
  font-size: 14px;
  color: #7a7a7a;
}

.about-us .right-image {
  position: relative;
  z-index: 1;
} */

.imgiphone {
  width: 100%;
  height: 10rem;
  background-image: url(../images/Group\ 21.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.img-iphone {
  width: 90%;
  margin: 0 auto;
}

.button--component button{
    border: none;
    color: white;
    border-radius: 0.3rem;
    cursor: pointer;
    background-color: #5532c8;
    width: 100%;
    min-width: 13rem;
    max-width: 20rem;
    height: 3rem;
    margin-top: 1rem;
}
.center{
  margin: 0 auto;
}
/*
---------------------------------------------
strategygoasl
-----------------------------------------------
*/
/* .strategygoals {
  width: 100%;
  height: 49rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 5rem 0;
  padding-right: 0;
  background-color: white;
  background-image: url(../images/Group\ 55.jpg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 50rem  90%;

}


.strategygoals__title{
  width: 99%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 4rem;
    margin-bottom: 2rem;
    text-align: center;
}

.strategygoals__list {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 90%;
  height: 95%;
  max-width: 1200px;
  padding: 0 2rem; 
  display: flex;
  margin: 0 auto;
}
.strategygoals__ul{
  
  padding-top: 0;
  padding-right: 0.2rem;
  width: 50%;
  z-index: 4;
}
.strategygoals__image{
  width: 50%;
 
}


.container--inicio{
  width: 100%;
  padding-right: var(--bs-gutter-x,.75rem);
  padding-left: var(--bs-gutter-x,.75rem);
  margin-right: auto;
  margin-left: auto;
  margin: 0  auto;
  max-width: 2800px;
}
#button--intro{
  width: 90%;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

#button--intro button:hover{
  opacity: 0.5;
}
.strategygoals__item {
  display: inline-flex;
  width: 100%;
  align-items: center;
  margin: 1rem 0;
}

.strategygoals__item img {
  width: 2rem;
  min-width: 2rem;
  max-width: 2rem;
  margin-right: 1rem;
}

.strategygoals__item p {
  font-weight: 300;
  color: #6e6b78;
  margin-bottom: 0;
}

.strategygoals__buttons{
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  justify-content: flex-start;

} */
/* 
  doopler
*/
  


/* 
---------------------------------------------
The Clients
---------------------------------------------
*/



.the-clients .section-heading {
  text-align: center;
  margin-bottom: 80px;
}

.the-clients .section-heading p {
  margin-top: 10px;
  margin-left: 18%;
  margin-right: 18%;

}

.menu .thumb h4 {
  margin-bottom: 0px;
  font-size: 20px;
  color: #2a2a2a;
}

.menu .thumb span.date {
  display: inline-block;
  margin-top: 0px;
  color: #0d081e;
}

.menu .thumb span.category,
.menu .thumb span.rating {
  font-weight: 700;
  color: #0d081e;
}

.menu .thumb i {
  color: #0d081e;
}

.the-clients .naccs .menu div {
  color: #fff;
  font-size: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: left;
  padding: 0px 15px;
  cursor: pointer;
  position: relative;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.the-clients .naccs .menu div.active h4,
.the-clients .naccs .menu div.active i,
.the-clients .naccs .menu div.active span.rating,
.the-clients .naccs .menu div.active .thumb {
  color: #5532c8;
}

.the-clients ul.nacc {
  position: relative;
  min-height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.the-clients ul.nacc li {
  opacity: 0;
  transform: translateX(-50px);
  position: absolute;
  list-style: none;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.the-clients ul.nacc li.active {
  transition-delay: 0.3s;
  position: relative;
  z-index: 2;
  opacity: 1;
  transform: translateX(0px);
}

.the-clients ul.nacc li {
  width: 100%;
}

.the-clients .nacc .thumb .client-content img {
  width: 76px;
  height: 62px;
}

.the-clients .nacc .thumb .client-content {
  padding: 60px 30px;
  /* background-image: url(../images/client-bg.png); */
  background-size: cover;
  border-radius: 50px;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  
}

.the-clients .nacc .thumb .client-content p {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  font-style: italic;
  margin-top: 30px;
}

.the-clients .nacc .thumb .down-content {
  margin-top: 30px;
}

.the-clients .nacc .thumb .down-content img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  display: inline;
}

.the-clients .nacc .thumb .down-content .right-content {
  display: inline-block;
  margin-left: 20px;
}

.the-clients .nacc .thumb .down-content .right-content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}

.the-clients .nacc .thumb .down-content .right-content span {
  color: #2a2a2a;
}


/* 


financial

*/


.information{
  background-color: #f5f5f7;
  height: 100%;
  padding:  0;
  padding-top: 9rem;


}
/* .offset-lg-2 {
  margin: 0;
} */



.financial__image {
  background-image: url(../images/Group\ 34.png);
  width: 100%;
  height: 85%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.financial__container {
  margin: 0 auto;
  width: 90%;
  max-width: 1200px;
  height: 36rem;
  background-color: white;
  border-radius: 2rem;
  padding-bottom: 5rem;
}

.financial__section2 {
  margin: 0 auto;
  width: 90%;
  max-width: 1200px;
  height: 37rem;
  border-radius: 2rem;
  display: flex;
  margin-top: 5rem;
  margin-bottom: 0rem;
}

.financial__ahorro {
  margin: 0 auto;
  width: 90%;
  max-width: 1200px;
  background-color: white;
  height: 37rem;
  border-radius: 2rem;
  display: flex;
  margin-top: 5rem;
}

.financial__ahorro--image {
  width: auto;
  margin-left: 1.2rem;
  display: flex;
  align-items: flex-end;
}

.financial__ahorro--texto {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.financial__title {
  width: 50%;
  padding-left: 2rem;
  padding-top: 2rem;
  margin-bottom: 2rem;
}

.financial__morado {
  padding: 2rem;
  width: 40%;
  margin-right: 2%;
  background-color: #9077e5;
  border-radius: 2rem;
  height: 100%;
}

.financial__morado h3 {
  margin-bottom: 5rem;
  color: white;
  font-weight: bold;
}

.financial__white {
  width: 60%;
  background-color: white;
  border-radius: 2rem;
}

.financial__white h3 {
  width: 80%;
  padding: 2rem;
  z-index: 10;
  position: relative;
}

.financial__white img {
  position: relative;
  left: 15%;
  bottom: 3rem;
  z-index: 0;
  width: max-content;
}

.financial--titulos {

  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.financial__notices {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 2rem;
  margin-top: 5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 3rem;
}

.financial__notices--box {
  height: 100%;
  min-height: 27rem;
  max-height: 32rem;
  border-radius: 2rem;
  text-align: center;
  margin: 0 1rem;
  padding: 2rem 0;
  padding-top: 1rem;
  background-color: white;
  min-width: 15rem;
}

.notices__images {
  height: 95%;
}



.financial__notices--box p {
  font-weight: 300;
  width: 90%;
  margin: 0 auto;
  letter-spacing: 0;
}


.financial__consejos2 {

  width: 90%;
  max-width: 1150px;
  height: 24rem;
  margin: 0 auto;
  border-radius: 2rem;
  margin-top: 5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2rem;
}

.consejos2--card {
  height: 100%;
  margin: 0.2rem;
  background-color: white;
  border-radius: 2rem;
  padding: 1rem;
}

.financial__app {
  width: 90%;
  background-color: white;
  padding-left: 1.5rem;
  border-radius: 2rem;
  margin: 0 auto;
  height: 33rem;
  max-width: 1200px;
  display: flex;
}

.financial__app--texto {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.financial__app--image {
  width: 45%;
  display: flex;

  align-items: flex-end;

}

/* 
---------------------------------------------
Pricing
---------------------------------------------
*/

.pricing-tables {
  padding-top: 130px;
}

.pricing-tables .section-heading {
  text-align: center;
  margin-bottom: 80px;
}

.pricing-tables .section-heading p {
  margin-top: 10px;
  margin-left: 18%;
  margin-right: 18%;
  letter-spacing: 3px;


}

.pricing-item-regular {
  margin-top: 30px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 50px;
  padding: 90px 30px;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.pricing-item-regular:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  content: '';
  width: 274px;
  height: 221px;
}

.pricing-item-regular:after {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  content: '';
  width: 370px;
  height: 171px;
}

.pricing-item-regular span.price {
  font-size: 40px;
  color: #fff;
  position: absolute;
  font-weight: 700;
  z-index: 1;
  left: 30px;
  top: 30px;
}

.pricing-item-regular h4 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
}

.pricing-item-regular .icon img {
  width: 120px;
  height: 106px;
  margin-bottom: 30px;
}

.pricing-item-regular ul li {
  color: #5532c8;
  font-size: 15px;
  margin-bottom: 15px;
}

.pricing-item-regular ul li:last-child {
  margin-bottom: 0px;
}

.pricing-item-regular ul li.non-function {
  color: #0d081e;
  text-decoration: line-through;
}

.pricing-item-regular .border-button {
  position: relative;
  z-index: 1;
  margin-top: 40px;
}

.pricing-item-pro {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 50px;
  padding: 120px 30px;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.pricing-item-pro:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  content: '';
  width: 281px;
  height: 251px;
}

.pricing-item-pro:after {
  position: absolute;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  content: '';
  width: 100%;
  height: 201px;
}

.pricing-item-pro span.price {
  font-size: 40px;
  color: #fff;
  position: absolute;
  font-weight: 700;
  z-index: 1;
  left: 30px;
  top: 30px;
}

.pricing-item-pro h4 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
}

.pricing-item-pro .icon img {
  width: 120px;
  height: 106px;
  margin-bottom: 30px;
}

.pricing-item-pro ul li {
  color: #5532c8;
  font-size: 15px;
  margin-bottom: 15px;
}

.pricing-item-pro ul li:last-child {
  margin-bottom: 0px;
}

.pricing-item-pro ul li.non-function {
  color: #0d081e;
  text-decoration: line-through;
}

.pricing-item-pro .border-button {
  position: relative;
  z-index: 1;
  margin-top: 40px;
}




/* 
---------------------------------------------
Free Quote
--------------------------------------------- 
*/

.free-quote {
  background-image: url(../images/quote.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 120px 0px;
  text-align: center;
  position: relative;
  z-index: 2;
  margin-top: 130px;
}

.free-quote .section-heading {
  margin-bottom: 60px;
}

.free-quote .section-heading h6,
.free-quote .section-heading h4 {
  color: #fff;
}

.free-quote .section-heading .line-dec {
  margin: 0 auto;
  background-color: #fff;
}

.free-quote form {
  background-color: #fff;
  display: inline-block;
  width: 100%;
  min-height: 80px;
  border-radius: 40px;
  position: relative;
  z-index: 1;
}

.free-quote form input {
  width: 100%;
  margin-top: 20px;
  margin-left: 30px;
  color: #0d081e;
  font-weight: 400;
  font-size: 15px;
  height: 40px;
  background-color: transparent;
  border-bottom: 1px solid #eee;
  border-top: none;
  border-left: none;
  border-right: none;
  position: relative;
  z-index: 2;
  outline: none;
}

.free-quote form button {
  width: 100%;
  height: 80px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  outline: none;
  border: none;
  margin-left: 30px;
  background-color: #726ae3;
  font-size: 15px;
  color: #fff;
}


/* 
---------------------------------------------
Footer Style
--------------------------------------------- 
*/
.logo--footer {
  fill: white;
  width: 5rem;
}

footer {
  background-color: #f5f5f7;
  background-image: url(../images/Group\ 45.png);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 13%;
  padding-bottom: 60px;
}

.footer-title {
  color: white;
  text-align: center;
}

footer .section-heading {
  margin-bottom: 45px;
  margin-top: 6rem;
}

footer .section-heading h4 {
  line-height: 45px;
  color: #fff;
  margin-left: 13%;
  margin-right: 13%;
  text-align: center;
}

footer #search {
  margin-bottom: 80px;
}

footer #search input::placeholder {
  color: #fff;
}

footer #search input {
  font-size: 15px;
  color: #fff;
  outline: none;
  height: 46px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #fff;
  width: 100%;
}
.container__suscribe{
  display: flex;
  justify-content: center;
}
footer #search button {
  height: 46px;
  border-radius: 23px;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  width: 100%;
  transition: all .5s;
  margin: 0 auto;
  width: 14rem;
}

footer #search button:hover {
  background-color: #fff;
  color: #5532c8;
}

.footer-widget .logo img {
  max-width: 96px;
  margin-bottom: 30px;
}

.footer-widget p {
  font-size: 15px;
  transition: all .3s;
  font-weight: 400;
}

.footer-widget--social {
  display: flex;
  flex-direction: row;
  width: 15rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.footer-widget--social button {
  width: 3rem;
  background: transparent;
  border: none;
}

.footer-widget h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 30px;
}

.footer-widget p {
  text-align: left;
  margin-bottom: 15px;
}

.footer-widget p,
.footer-widget p a {
  color: #fff;
}

.footer-widget ul {
  display: inline;
  max-width: 40%;
  float: left;
  margin-right: 10%;
}

.footer-widget ul li {
  margin-bottom: 10px;
}

.footer-widget ul li:last-child {
  margin-bottom: 0px;
}

.footer-widget ul li a {
  font-size: 15px;
  color: #fff;
  transition: all .3s;
  ;
}

.footer-widget a:hover {
  opacity: 0.75;
}

footer .copyright-text p {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 0px;
  color: #fff;
  font-size: 15px;
}

footer p a {
  color: #fff;
  transition: all .5s;
}

footer p a:hover {
  opacity: 0.75;
  color: #fff;
}


/*
---------------------------------------------
PopUp
---------------------------------------------
*/

#lean_overlay {
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: #000;
  display: none;
}

.popupContainer {
  position: absolute;
  width: 330px;
  height: auto;
  left: 45%;
  top: 60px;
  background: #FFF;
}

.btn {
  padding: 10px 20px;
  background: #5532c8;
  color: #fff;
  transition: all .3s;
}

.btn_red {
  background: #5532c8;
  color: #FFF;
}
.title__button--si{
  width: 50%;
  height:3rem;
  background: #5532c8;
  border: none;
  color: white;
  border-radius: 0.2rem;
  margin: 1rem auto;
}
.title__button--si:hover{
  opacity: 0.5;
}
.btn:hover {
  background: #E4E4E2;
}

.btn_red:hover {
  color: #2a2a2a;
  background: #E4E4E2;
}

a.btn {
  color: #fff;
  text-align: center;
  text-decoration: none;
}

a.btn_red {
  color: #FFF;
}

.one_half {
  width: 50%;
  display: block;
  float: left;
}

.one_half.last {
  width: 45%;
  margin-left: 5%;
}

/* Popup Styles*/

.popupHeader {
  font-size: 16px;
  text-transform: uppercase;
}

.popupHeader {
  background: #F4F4F2;
  position: relative;
  padding: 10px 20px;
  border-bottom: 1px solid #DDD;
  font-weight: bold;
}

.popupHeader .modal_close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 15px;
  background: #4b8ef1;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
}

.popupBody {
  padding: 20px;
}

.social_login .social_box {
  display: block;
  clear: both;
  padding: 10px;
  margin-bottom: 10px;
  background: #F4F4F2;
  overflow: hidden;
}

.social_login .icon {
  display: inline-block;

  padding: 5px 10px;
  margin-right: 10px;
  float: left;
  color: #FFF;
  font-size: 16px;
  text-align: center;
}

.social_login .fb .icon {
  background: #3B5998;
}

.social_login .google .icon {
  background: #DD4B39;
}

.social_login .icon_title {
  display: block;
  padding: 5px 0;
  float: left;
  font-weight: bold;
  font-size: 16px;
  color: #777;
}

.social_login .social_box:hover {
  background: #E4E4E2;
}

.centeredText {
  color: #0d081e;
  text-align: center;
  margin: 20px 0;
  clear: both;
  overflow: hidden;
  text-transform: capitalize;
}

.user_login label {
  color: #0d081e;
}

.action_btns {
  clear: both;
  overflow: hidden;
}

.action_btns a {
  display: block;
}

/* User Login Form */

.user_login {
  display: none;
}

.user_login label {
  display: block;
  margin-bottom: 5px;
}

.user_login input[type="text"],
.user_login input[type="email"],
.user_login input[type="password"] {
  display: block;
  width: 90%;
  padding: 10px;
  border: 1px solid #DDD;
  color: #666;
}

.user_login input[type="checkbox"] {
  float: left;
  margin-right: 10px;
  width: 22px;
  height: 22px;
}

.user_login input[type="checkbox"]+label {
  float: left;
}

.user_login .checkbox {
  margin-bottom: 10px;
  clear: both;
  overflow: hidden;
}

.forgot_password {
  display: block;
  margin: 20px 0 10px;
  clear: both;
  overflow: hidden;
  text-decoration: none;
  color: #4b8ef1;
  transition: all .3s;
}

/* User Register Form */

.user_register {
  display: none;
}

.user_register label {
  color: #0d081e;
  display: block;
  margin-bottom: 5px;
}

.user_register input[type="text"],
.user_register input[type="email"],
.user_register input[type="password"] {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #DDD;
  color: #666;
}

.user_register input[type="checkbox"] {
  width: 22px;
  height: 22px;
  float: left;
  margin-right: 8px;
}

.user_register input[type="checkbox"]+label {
  float: left;
  color: #0d081e;
}

.user_register .checkbox {
  margin-bottom: 10px;
  clear: both;
  overflow: hidden;
}


.title--estrategies {
  width: 99%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 0;
  margin-bottom: 2rem;
  text-align: center;
}

.title__estrategy--first {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 0rem;
}

.title--primary {
  font-size: 50px;
  color: white;
}

.container--titulos{
  width: 90%;
    text-align: left;
    max-width: 1150px;
    margin: 0 auto;
    margin-bottom: 2rem;
}

/* 
---------------------------------------------
  Benefits
---------------------------------------------
*/




/*
---------------------------------------------
responsive
--------------------------------------------- 
*/

@media (max-width: 1000px) {
  
}

@media (max-width: 1200px) {
  /* .strategygoals{
    background-size: 50%;
  } */
  .header-area .main-nav .logo h4 {
    font-size: 24px;
  }

  .header-area .main-nav .logo h4 img {
    max-width: 25px;
    margin-left: 0px;
  }

  .header-area .main-nav .nav li:last-child {
    padding-left: 20px;
  }

  .main-banner .left-content h2 {
    z-index: 2;
    position: relative;
    font-weight: 700;
    line-height: 70px;
    font-size: 50px;
    margin-bottom: 20px;
  }

 
}
#button--intro1{
  width: 100%;
  justify-content: flex-end;
  text-align: right;
  float: right;
  margin-right: 2rem;
}
#button--intro1 button{
  border: none;
    color: white;
    border-radius: 0.3rem;
    cursor: pointer;
    background-color: #5532c8;
    width: 100%;
    max-width: 17rem;
    height: 3rem;
}
#button--intro1 button:hover{
  opacity: 0.5;
}
.left{
  justify-content: flex-start !important ;
}

#button--intro{
  width: 100%;
  justify-content: center;
  margin: 0 auto;
}
.button--derecha{
  text-align: right;
  margin: 0;
}
@media (max-width: 992px) {
  .header-area .main-nav .nav li {
    padding-left: 0;
    padding-right: 5px;

  }
  #button--intro1{
    width: 100%;
    justify-content: center;
    float: center;
    text-align: center;
    margin-right: 0;
  }
  .container__suscribe{
    display: flex;
    justify-content: flex-start;
  }

  #button--intro{
    width: 100%;
    justify-content: center;

  }
  .imagelogo{
    max-width: 4rem;
  }

  .title__zenefin p{
    display: none;
  }
  .title--primary{
    color: black;
  }
  .future__finance{
    width: 100%;
    margin: 0 auto;
  }
  h2 {
    font-size: calc(1.375rem + 1.5vw);

  }

  h3 {
    font-size: calc(1.325rem + .9vw);

  }

  .clients__button--list {
    margin: 0 3%;
    width: 10rem;
  }


  footer .section-heading h4 {
    color: #5532c8;
  }

  .footer-widget--social {
    width: 90%;
    margin-bottom: 3rem;
  }
  .footer-widget ul{
    float: none;
  }

  .footer-widget--social button {
    width: 4rem;
    background: transparent;
    border: none;
    margin-right: 2rem;

  }

  .financial__notices--box {
    margin: 0.2rem;
  }

  .consejos2--card {
    height: 33rem;
    margin: 0 auto;
  }

  .the-clients {
    padding-left: 0%;
    height: auto;
    align-items: center;
    padding: 3rem 0;
  }

  .financial {
    height: auto;
  }

  .financial__container {
    width: 90%;
    height: 26rem;
  }
  .footer-title{
    color: white;
  }
  .fa-arrow-right {
    display: none;
  }

  .financial__app {
    margin-top: 0;
    height: 25rem;
  }
 

  .financial__image {
    background-position: bottom;
  }

  .financial__app--image {
    display: flex;
    align-items: flex-end;
  }

  .financial__section2 {
    width: 90%;
    height: auto;
    margin-bottom: 4rem;
    flex-wrap: wrap;
  }

  .financial__morado {
    width: 100%;
    height: 30%;
    margin-bottom: 2rem;

  }

  .financial__ahorro {
    width: 90%;
    margin-top: 4rem;
    height: auto;
  }

  .financial__ahorro--texto {
    width: 100%;
    height: 100%;
    margin-top: 15%;
  }

  .financial__morado h3 {
    margin-bottom: 1.3rem;
  }

  .financial__morado img {
    max-width: 26rem;
    margin-left: 14%;
  }

  .financial__white {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 53%;
    padding: 0 1.5rem;
  }

  .financial__white img {
    position: static;
    bottom: 12.7rem;
    left: 18%;
    z-index: 0;
    width: 100%;
    padding: 0 5rem;

  }

  .financial__white h3 {
    margin: 0 auto;
    width: 100%;
    padding-right: 0;
  }
  .consejos2--card {
    margin-bottom: 2rem;
  }

  .financial__consejos2 {
    flex-direction: column;
    justify-content: unset;
    align-items: flex-start;
  }
  .clients__phone {
    width: 50%;
    bottom: 50rem;
    margin-left: 2%;
  }

  
    .about__button{
      width: 44%;
    }
 
 
  .header-area {
    background-color: #fff;
  }

  .main-banner:after {
    display: none;
  }

  
  .main-banner .left-content p {
  width: 100%;
  color: #6e6b78;
  margin: 1rem 0;
  }
  #container__inicio--country{
    width: 100%;
  }
  
  .main-banner .left-content {
    margin: 0 auto;
  }
 
  .future__finance{
    width: 100%;
    padding-bottom: 0;

  }

  form#contact {
    overflow: hidden;
  }

  .header-area .main-nav .logo h4 {
    font-size: 20px;
  }

  .main-banner .left-content {
    width: 100vw;
  }
 
  .main-banner {
    text-align: center;
    padding-top: 5rem;
    height: auto;
    text-align: center;
    padding: 126px 0px 30px 0px;
  }

  .main-banner:before {
    display: none;
  }

  .main-banner .right-image {
    margin: 30px auto 0px auto;
    text-align: center;
  }
  .main-banner .right-image img{
    width: 550px;
  }
  .features-item {
    margin-bottom: 45px;
  }

  .last-features-item,
  .last-skill-item {
    margin-bottom: 0px !important;
  }

  .skill-item {
    margin-bottom: 30px;
  }

  .about-left-image img {
    margin-right: 0px;
    margin-bottom: 45px;
    ;
  }

  .about-us .box-item {
    text-align: center;
  }

  .about-us:after {
    display: none;
  }

  .about-us .gradient-button,
  .about-us span {
    text-align: center;
    display: block;
  }

  .about-us .right-image {
    margin-top: 30px;
  }
  .about-us .section-heading {
    text-align: center;
  }

  .about-us .left-image {
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 45px;
  }

  .blog-posts {
    margin-left: 0px;
    margin-top: 30px;
  }

  .post-item {
    margin-bottom: 70px;
  }

  .pricing-item-regular {
    margin-bottom: 30px;
  }

  .our-portfolio .owl-nav {
    display: none !important;
  }

  .contact-info {
    margin-top: 60px;
  }

  form#contact {
    padding: 45px;
  }

  .header-area .main-nav .nav {
    right: 2rem;
  }

  footer {
    padding-top: 25rem;
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  
  .app h2{
    font-size: 27px;
  }
  .app h3{
   font-size:  calc(1.525rem + .9vw);
  }
  .footer-widget{
    float: left;
    text-align: left;
    padding-left: 0.5rem;
  }
  .information{
    padding-top: 7rem;
  }

  .strategygoals__buttons a{
    margin-right: 8rem;
  }
  .title--strategy{
    font-size: calc(1.325rem + .9vw);

  }
  .clients__texto h2{
    font-size: calc(1.325rem + .9vw);
    
  }
  .about__title h2{
    padding:  0 0.5rem;
    font-size: calc(1.325rem + .9vw);
  }
  .about__container{
    padding: 2.9rem 0;
    padding-bottom: 2.9rem;
  }
  .main-banner{
    padding-top: 5rem;
    height: 100%;
    text-align: center;
  }
  .main-banner .left-content p {
  width: 100%;
  color: #6e6b78;
  margin: 1rem 0;
  }
  #container__inicio--country{
    width: 100%;
  }
  
  .main-banner .left-content {
    margin: 0;
  }
  .future__finance{
    width: 90%;
    padding-bottom: 0;
    margin: 0;

  }
  .header-area{
    background-color: #fff8e4;
    height: 80px;
  }
  .container--inicio{
    padding:  0;
    padding-bottom: 0;
  }
  #container__inicio--country{
    color: #6e6b78;
  }
  .title--primary{
    color: #5532c8;
    line-height:1.2;
    font-weight: 700;
    font-size: 32px;
  }
  .container--inicio .row{
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    width: 100%;
  }
  .padding{
    padding: 0;
  }
  
  .title__button--si{
    width: 74%;
    margin:1.5rem auto;
    height:3.4rem;
    background: #5532c8;
    border: none;
    color: white;
    border-radius: 0.2rem;
    position: relative;
    max-width: 18.5rem;
    top: 15rem;
  }

  .clients__button--list {
    width: 10rem;
    margin: 0 4%;
  }

  .section-heading {
    position: relative;
    z-index: 2;
    width: 90%;
    margin: 0 auto;
  }

  .footer-widget--social button {
    margin: 0.2rem;
    width: 3rem;
  }

  .header-area .main-nav .nav {
    right: 0;
  }

  .financial__notices {
    flex-wrap: wrap;
    background-color: transparent;
    margin-top: 3rem;
  }

  .financial__notices--box {
    margin-bottom: 1rem;
    background-color: white;
  }

  .financial__app {
    margin-top: 2rem;
    height: 34rem;
    padding: 1rem;
    padding-bottom: 0;
    flex-direction: column;
  }

  .financial__app--texto {
    width: 100%;
  }

  .financial__app--image {
    width: 100%;
  }

  
  .financial__ahorro {
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 3rem;
  }

  .financial {
    width: 100%;
    margin: 0 auto;
    height: auto;
    background-color: white;
    padding-top: 3rem;

  }
  .main-banner .right-image img {
    width: 100%;
  }
  .financial__ahorro--image {
    width: 100%;
    overflow: hidden;
    margin: 0;
  }

  .financial__ahorro--image {
    position: relative;
    left: 3rem;
  }

  .financial__ahorro--texto {
    margin: 0;
    height: 4rem;
    margin: 1.2rem;
    width: 95%;

  }


  .financial__white h3 {
    width: 100%;
    padding: 2rem 1rem;
  }

  .financial__section2 {
    width: 90%;
    height: auto;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    margin-bottom: 0;
    margin-top: 3rem;
  }

  .financial__white img {
    position: relative;
    bottom: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    padding: 0;
  }

  .financial__title {
    width: 95%;
  }

  .financial__morado {
    height: 20rem;
    margin-bottom: 2rem;

  }

  .financial__morado img {
    width: 100%;
    max-width: 30rem;
    margin-left: 0;
    padding: 0 2%;
  }

  .financial__white {
    height: 46%;
  }

  .financial__image {
    background-size: contain;
    background-position: bottom;
    background-image: url(../images/Group\ 38.png);
  }
  #button--intro1{
    width: 100%;
    margin-right:0;
  }
  .financial__container {
    height: 30rem;
    padding-bottom: 6rem;
  }

  .financial--titulos {
    text-align: center;
  }
  .footer-widget--social{
    display: flex;
    justify-content: cWenter;
    width: 100%;
  }
  h2 {
    font-size: calc(1.375rem + 1.5vw);

  }

  h3 {
    font-size: calc(1.325rem + .9vw);

  }

  .scroll-to-section a {
    margin-right: 0;
  }
  
  .title__estrategy--first{
    margin-top: 2.5rem;
  }

  .clients__button {
    width: 90%;
    margin: 0 auto;
    margin: 1.5rem auto;
    height: 3.4rem;

  }


  .banderas {
    z-index: 10;
    margin: 0 auto;
    width: 100%;
    padding: 0.5rem;
    background-color: #f5f5f7;
  }

  
  .imagelogo{
    max-width: 4rem;
  }
  
  .about__button {
    width: 90%;
  }

  .imgiphone {
    background-size: cover;
    height: 8rem;
  }

  

  .the-clients .naccs .menu div {
    text-align: center;
  }
  footer .section-heading h4 {
    color: #2a2a2a;
  }

  footer #search {
    margin-bottom: 45px;
  }

  footer {
    padding-top: 0px;
    text-align: center;
  }

  .footer-widget ul {
    display: block;
    width: 100%;
    float: left;
    text-align: center;
    margin: 0 auto;
    padding-left: 0;
  }

  .footer-widget h4 {
    margin-top: 45px;
    margin-bottom: 20px;
  }

  footer #search input {
    border: 1px solid #eee;
    color: #0d081e;
    background-color: #fff;
    border-radius: 23px;
    margin-bottom: 15px;
    padding: 0px 30px;
  }

  footer #search button {
    background-color: #fff;
    color: #4b8ef1;
  }

  footer #search input::placeholder {
    color: #2a2a2a;
  }

  .header-area .main-nav .logo h4 {
    font-size: 30px;
  }

  .header-area .main-nav .logo h4 img {
    max-width: 30px;
    margin-left: 5px;
  }

  .main-banner .info-stat {
    margin-bottom: 15px;
  }

  .menu .thumb span.date {
    margin-bottom: -20px;
    display: block;
  }
  #foooter--nosotros{
    margin-top: 0;
  }

  .service-item {
    text-align: center;
    padding: 25px;
    width: 100%;
    height: 18rem;
  }
  .menu .thumb span.category {
    display: none;
  }

  .about-us .right-image {
    margin-top: 30px;
  }
/* 
  .service-item .icon {
    float: none;
    margin-right: 0px;
    margin-bottom: 15px;
  } */

  .the-clients .nacc .thumb .down-content .right-content {
    margin-left: 0px;
    margin-top: 15px;
  }
  /* .service-item .right-content {
    display: inline-block;
  }

  .services .naccs .menu div .thumb {
    padding: 5px;
  }

  .services .icon img {
    margin: 0px;
  }


  .services ul.nacc li.active {
    padding: 45px;
  }

  .services .naccs .menu div {
    font-size: 0px;
  }

  .services ul.nacc li .right-image img {
    float: none;
  } */
  .the-clients .nacc .thumb .down-content {
    text-align: center;
  }


  .our-portfolio .section-heading,
  .about-us .section-heading,
  .about-us .about-item,
  .about-us p,
  .about-us .main-green-button {
    text-align: center;
  }

  .our-portfolio .section-heading .line-dec {
    margin: 0 auto;
  }

  .our-services .section-heading {
    margin-left: 15px;
    margin-right: 15px;
  }

  .free-quote form input {
    margin-left: 0px;
    padding: 0px 30px;
  }

  .free-quote form button {
    margin-left: 0px;
    border-bottom-left-radius: 40px;
    border-top-right-radius: 0px;
  }

  .blog-posts {
    margin-left: 0px;
    margin-top: 30px;
  }

  .footer-title {
    color: #5532c8;
  }

  #newsletter {
    margin-top: 0;
  }

  .post-item {
    margin-bottom: 30px;
  }

  .post-item .thumb img {
    max-width: 140px;
  }

  .post-item .right-content p {
    display: none;
  }

  .about-us .about-item {
    margin-top: 15px;
  }

  form#contact {
    padding: 30px;
  }

  .consejos2--card {
    margin-bottom: 1rem;
  }
  .header-area .main-nav .menu-trigger {
    top: 25px;
  }
  .footer{
  background-position-x: -89rem;

  }
  .fa-arrow-right{
    display: none;
  }
  #button--intro{
    justify-content: center !important;
  }
}

