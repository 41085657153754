.privacity{
    width: 80%;
    max-width: 1140px;
    margin: 0 auto;
}
.privacity__title{
    margin: 2rem 0;
    text-align: center;
    font-size: 40px;
    font-weight: 400;
    line-height: .80;
}
p{
   color: #2b5069;
}
.mb--2{
    margin-bottom: 2rem;
}
.foooterlanding__privacy span{
    margin-right: 0.5rem;
}
