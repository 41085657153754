/*
------------------------------------------
    mision
------------------------------------------
*/

.mision {
    width: 100%;

    background-color: #f5f5f7;
}

.mision__container {
    padding: 2rem;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 8rem;
    display: flex;
    background-color: #f5f5f7;
}

.mision--p {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 16px;
    color: #838188;
}

.mision--info {
    color: #212529;
    font-size: 40px;
    line-height: 1.2;
    font-weight: 500;
}

.mision__image {
    width: 50%;
    padding: 1rem;
    display: flex;
    align-items: center;
    align-content: center;
}

.mision__image img {
    width: 100%;
    max-width: 100%;
    border-radius: 2rem;
}

.mision__text {
    width: 50%;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


@media (max-width: 992px) {
    .mision {
        width: 100%;
    }
    .mision__container {
        width: 90%;
        flex-direction: column;
        margin-top: 2rem;
    }
    .mision--info {
        color: #212529;
        font-size: 38px;
        line-height: 1.2;
        font-weight: 500;
    }
    .mision__image {
        width: 80%;
        margin: 0 auto;
    }
    .mision__text {
        width: 100%;
    }

}
@media (max-width: 767px) {
    .mision{
        margin-top: ;
        width: 100%;
        border-radius: 0;
        box-shadow: none;
      }
      .mision--info{
        font-size: 22px;
      }
      .mision__container{
        width: 100%;
        margin-top: 0;
        flex-direction: column;
        padding: 2rem 1rem;
      }
      .mision__image {
        width: 100%;
        margin: 0 auto;
      }
}